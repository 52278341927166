const configReducer =  (
  state = {
    api_url: process.env.API_URL,
    payment_redirect_url: process.env.PAYMENT_REDIRECT_URL,  // "https://dev.paymentredirect.com", 
    image_base_url: process.env.IMAGE_BASE_URL+"/images",
    web_redirect_url:process.env.WEB_REDIRECT_URL
  },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default configReducer;
