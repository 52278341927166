import React from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import {
  cryptoCurrencyList,
  currencyList,
  withdrawMerchantList
} from "../../data/merchantList";

const iframeStyle = {
  height: "640px",
  width: "100%",
};

export const WithDrawModal = ({
  color,
  show,
  onHide,
  errorMessage,
  withdrawValue,
  selectedCyptoCurrency,
  onCyptoCurrencyChange,
  onAmountInputChange,
  selectedCurrency,
  onCurrencyChange,
  prefix,
  submitData,
  onAmountClick,
  handleChange,
  inputState,
  redirectUrl,
  redirectedModal,
  onClose,
  selectedMerchant,
  onMerchantChange,
}) => {

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1d1d1d" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#fff" }}
          >
            Make a Withdrawal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px", backgroundColor: "#000" }}>
          <div className="backDiv">
          <i className="fa-solid fa-arrow-left"></i>
            <br />
            <span style={{ fontWeight: "900", fontSize: "1.2rem" }}>
              Withdraw
            </span>
            <p>Please select payment method</p>
          </div>

          <div className="warningDiv">
            <span>
              KYC verfication is required within 27 days of your registration.
              Please proceed with the verification today to continue using our
              service.
            </span>
            <span>
              <u>Click Here</u>
            </span>{" "}
          </div>
          <div className="mainDiv">
            <div>
              <span>CRYPTON</span>
              <br />
              <span>PAYMENTS</span>
            </div>
            <div className="card">
              <h5 className="card-header">Withdraw Details</h5>
              <div className="card-body">
                <select
                  value={selectedMerchant}
                  className="form-control selectMerchant"
                  id="selectMerchantId"
                  name="selectMerchant"
                  data-e2e="selectMerchant"
                  onChange={(e) => {
                    onMerchantChange(e.target.value);
                  }}
                >
                  <option value={"{}"}>Select Merchant</option>
                  {withdrawMerchantList &&
                    withdrawMerchantList.length > 0 &&
                    withdrawMerchantList.map((data, index) => {
                      return (
                        <option
                          key={index}
                          data-e2e={data.name}
                          value={JSON.stringify(data)}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                </select>
                {Object.keys(selectedMerchant).length > 0 && (
                  <div style={{ marginTop: 20, lineHeight: "25px" }}>
                    <div className="form-group row">
                      <label htmlFor="network" className="col-3 col-form-label">
                        Crypto Currency:
                      </label>
                      <div className="col-9">
                        <Select
                          placeholder="Select Crypto Currency"
                          defaultValue={0}
                          options={cryptoCurrencyList} // set list of the data
                          onChange={onCyptoCurrencyChange} // assign onChange function
                          value={cryptoCurrencyList.find(
                            (obj) => obj.value === selectedCyptoCurrency
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="network" className="col-3 col-form-label">
                        Network:
                      </label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="network"
                          data-e2e="network"
                          id="network"
                          value={inputState.network}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="merchantId"
                        className="col-3 col-form-label"
                      >
                        Id:
                      </label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="merchantId"
                          id="merchantId"
                          data-e2e="merchantId"
                          value={inputState.merchantId}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="merchantName"
                        className="col-3 col-form-label"
                      >
                        Name:
                      </label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="merchantName"
                          id="merchantName"
                          data-e2e="merchantName"
                          value={inputState.merchantName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="recipientWallet"
                        className="col-3 col-form-label"
                      >
                        Recipient Wallet:
                        <span style={{ color:`${color}`}}>&nbsp;&nbsp; *</span>
                      </label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="recipientWallet"
                          id="recipientWallet"
                          value={inputState.recipientWallet}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="apiKey" className="col-3 col-form-label">
                        ApiKey:
                      </label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="apiKey"
                          id="apiKey"
                          data-e2e="apiKey"
                          value={inputState.apiKey}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{prefix}</span>
                  </div>
                  <input
                    type="number"
                    name="withdrawAmount"
                    className="form-control"
                    value={parseFloat(withdrawValue)}
                    onChange={onAmountInputChange}
                    aria-label="Text input with dropdown button"
                  />
                  <Select
                    className="currencyBox"
                    placeholder="Select"
                    getOptionLabel={(currencyList) => currencyList.value}
                    value={currencyList.find(
                      (obj) => obj.value === selectedCurrency
                    )} // set selected value
                    options={currencyList} // set list of the data
                    onChange={onCurrencyChange} // assign onChange function
                  />
                </div>
                <div className="row mt-2 ml-0 mr-0">
                  <div className="col-3 box" onClick={() => onAmountClick(25)}>
                    +25
                  </div>
                  <div className="col-3 box" onClick={() => onAmountClick(50)}>
                    +50
                  </div>
                  <div className="col-3 box" onClick={() => onAmountClick(75)}>
                    +75
                  </div>
                  <div className="col-3 box" onClick={() => onAmountClick(100)}>
                    +100
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="depositBtn"
              size="lg"
              block
              onClick={submitData}
              disabled={
                !(selectedCyptoCurrency.value !== "" && withdrawValue > 0)
              }
            >
              Withdraw
            </Button>
            {redirectUrl && (
              <div className="mt-2 text-center"> {redirectUrl} </div>
            )}
            {errorMessage && <div className="error"> {errorMessage} </div>}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={redirectedModal} onHide={onClose} dialogClassName="my-modal">
        <Modal.Header closeButton style={{ backgroundColor: "#1d1d1d" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#fff" }}
          >
            Send Withdraw
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0", backgroundColor: "#000" }}>
          <iframe
          title="iframe4"
          data-e2e="iframe4"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            src={redirectUrl}
            width="500"
            height="500"
            style={iframeStyle}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
