import axios from 'axios';

export const getDeviceIP = () => new Promise((resolve, reject) => {
  axios.get('https://api.ipify.org?format=json')
    .then(function (response) {
      resolve(response.data.ip)
    })
    .catch(function (error) {
      resolve(null)
    });
})
