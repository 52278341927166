import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DepositModal } from "../components/DepositModal";
import DepositWithCard from "../components/DepositWithCard";
import { GatewayModal } from "../components/GatewayModal";
import * as DepositService from "../services/DepositService";
import moment from "moment";


const Home = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const Styles = {
    backgroundImage: `url("${image_base_url}/merchantDeposit/bg1.png")`,
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    position: "relative",
  };
  const apiUrl = useSelector((state) => state.config.api_url);
  const [showDepositModal, setDepositModal] = useState(false);
  const [depositValue, setDepositValue] = useState(0);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showGatewayModal, setGatewayModal] = useState(false);
  const [show2, setShow2] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [prefix, setPrefix] = useState("$");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [depositWithCardModal, setDepositWithCardModal] = useState(false);
  const [inputState, setInputState] = React.useState({
    merchantId: "",
    merchantName: "",
    merchantUserEmail: "",
    merchantUserId: "",
    merchantTransactionid: "",
    apiKey: "CorrectSecretKey",
    network: "testnet",
    "firstName": "",
    "lastName": "",
    "billingAddress1": "",
    "billingCity": "",
    "billingPostcode": "",
    "billingState": "",
    "billingCountry": "",
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setInputState({
      ...inputState,
      [evt.target.name]: value,
    });
  }

  function onAmountInputChange(evt) {
    const value = evt.target.value;
    setDepositValue(parseFloat(value));
  }

  const onAmountClick = (value) => {
    setDepositValue(depositValue + value);
  };
  const submitData = () => {
    const dataObj = {
      merchantId: inputState.merchantId,
      name: inputState.merchantName,
      merchantUserEmail: inputState.merchantUserEmail,
      amount: (depositValue * 100).toString(),
      currency: selectedCurrency,
      brand: "exampledatabrand",
      useBrand: "exampledatausebrand",
      merchantUserId: inputState.merchantUserId,
      merchantTransactionId: inputState.merchantTransactionid && inputState.merchantTransactionid !== "" ? inputState.merchantTransactionid : "merchanttransaction-" + moment().valueOf(),
      apiKey: inputState.apiKey,
      network: inputState.network,
      hostname: window.location.hostname,
      "firstName": inputState.firstName,
      "lastName": inputState.lastName,
      "billingAddress1": inputState.billingAddress1,
      "billingCity": inputState.billingCity,
      "billingPostcode": inputState.billingPostcode,
      "billingState": inputState.billingState,
      "billingCountry": inputState.billingCountry,
      "returnToMerchantUrl": inputState.returnToMerchantUrl,
    };
    DepositService.postData(dataObj, apiUrl)
      .then((response) => {
        if (response.data.Error) {
          setErrorMessage(response.data.Error);
          return;
        } else {
          setErrorMessage(null);
          // setShow2(true);
          setRedirectUrl(response.data.transactionURL);
        }
      })
      .catch((e) => {
        if (e) {
          setErrorMessage("Server Error");
          return;
        }
      });
  };
  return (
    <div style={Styles}>
      <Button
        className="buttonNew text-right"
        variant="danger"
        data-e2e="deposit"
        onClick={() => setGatewayModal(true)}
      >
        Deposit
      </Button>
      <Button
        className="buttonNew text-right ml-10"
        variant="danger"
        data-e2e="deposit-with-card"
        onClick={() => setDepositWithCardModal(true)}
      >
        Deposit With Card
      </Button>
      {depositWithCardModal && (
        <DepositWithCard
          show={depositWithCardModal}
          onHide={() => setDepositWithCardModal(false)}
        />
      )}
      <GatewayModal
        show={showGatewayModal}
        onHide={() => setGatewayModal(false)}
        onClick={() => {
          setGatewayModal(false);
          setDepositModal(true);
        }}
      />

      <DepositModal
        show={showDepositModal}
        onHide={() => setDepositModal(false)}
        onMerchantChange={(event) => {
          let eventObj = JSON.parse(event);
          if (event === "{}") {
            setInputState({
              merchantId: "",
              merchantName: "",
              merchantUserEmail: "",
              merchantUserId: "",
              merchantTransactionid: "",
              apiKey: "CorrectSecretKey",
              network: "testnet",
              "firstName": "",
              "lastName": "",
              "billingAddress1": "",
              "billingCity": "",
              "billingPostcode": "",
              "billingState": "",
              "billingCountry": "",
              "returnToMerchantUrl": "https://www.google.com/",
            });
            setSelectedMerchant({});
          } else {
            setInputState({
              merchantId: eventObj.merchantId,
              merchantName: eventObj.name,
              merchantUserId: eventObj.merchantUserId,
              merchantTransactionid: eventObj.merchantTransactionid,
              merchantUserEmail: eventObj.supportEmail,
              apiKey: eventObj.testApiKey.key,
              network: eventObj.network ? eventObj.network : inputState.network,
              "firstName": "",
              "lastName": "",
              "billingAddress1": "",
              "billingCity": "",
              "billingPostcode": "",
              "billingState": "",
              "billingCountry": "",
              "returnToMerchantUrl": "https://www.google.com/",
            });
            setSelectedMerchant(event);
          }
        }}
        onAmountInputChange={onAmountInputChange}
        onCurrencyChange={(event) => {
          setPrefix(event.symbol);
          setSelectedCurrency(event.value);
        }}
        handleChange={handleChange}
        inputState={inputState}
        onAmountClick={onAmountClick}
        depositValue={depositValue}
        selectedMerchant={selectedMerchant}
        selectedCurrency={selectedCurrency}
        prefix={prefix}
        submitData={submitData}
        errorMessage={errorMessage}
        redirectUrl={redirectUrl}
      />

      <Modal show={show2} onHide={() => setShow2(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="confimationModal">
          Choose where do you want to redirect!
          {redirectUrl && (
            <div style={{ wordWrap: "break-word" }}>
              <b data-e2e="redirect-url" data-e2e-url={redirectUrl}>Redirect URL:</b> {redirectUrl}{" "}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="depositBtn"
            onClick={() =>
              window.open(
                redirectUrl,
                "myWindow",
                "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=939,height=910"
              )
            }
          >
            Modal
          </Button>
          <Button
            className="depositBtn"
            onClick={() =>
              window.open(
                redirectUrl,
                "myWindow",
                "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=939,height=800"
              )
            }
          >
            New Window
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Home;
