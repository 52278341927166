import React, {useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import {
  currencyList,
  newMerchantList
} from "../data/merchantList";

const boxStyle = {
  border: "5px #356d4f double",
  padding: "5%",
}


const iframeStyle = {
  height: "585px",
  width: "100%",
}
// const MyInput = ({ name, label, ...rest }) => {
//   return (
//     <>
//       <label htmlFor={name}>{label}</label>
//       <input name={name} placeholder="Jane" {...rest} />
//     </>
//   );
// };
export const DepositModal = ({
  show,
  onHide,
  errorMessage,
  depositValue,
  selectedMerchant,
  onMerchantChange,
  onAmountInputChange,
  selectedCurrency,
  onCurrencyChange,
  prefix,
  submitData,
  onAmountClick,
  handleChange,
  inputState,
  redirectUrl,
}) => {
  const [redirectedModal, setRedirectedModal] = useState(false);
  const [dimensionsInput, setDimensionInput] = useState({
    windowWidth: 0,
    windowHeight: 0,
    show: false
  })



  function handleInputChange(evt) {
    const value = evt.target.value;
    setDimensionInput({
      ...dimensionsInput,
      [evt.target.name]: value
    });
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-90w modal-91w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1d1d1d" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#fff" }}
          >
            Make a Deposit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px", backgroundColor: "#000" }}>
          {/* <form onSubmit={submitData}> */}
          <div className="backDiv">
          <i className="fa-solid fa-arrow-left"></i>
            <br />
            <span data-e2e="deposit" style={{ fontWeight: "900", fontSize: "1.2rem" }}>Deposit</span>
            <p>Please select payment method</p>
          </div>

          <div className="warningDiv">
            <span>
              KYC verfication is required within 27 days of your registration.
              Please proceed with the verification today to continue using our
              service.
            </span>
            <span>
              <u>Click Here</u>
            </span>{" "}
          </div>
          <div className="mainDiv">
            <div>
              <span>CRYPTON</span>
              <br />
              <span>PAYMENTS</span>
            </div>
            <div className="card">
              <h5 className="card-header">Merchant Details</h5>
              <div className="card-body">
                {/* <Select
                placeholder="Select Merchant"
                data-e2e="selectMerchant"
                getOptionLabel={(newMerchantList) => newMerchantList.name} // set selected value
                getOptionValue={(newMerchantList) => newMerchantList._id}
                options={newMerchantList} // set list of the data
                onChange={onMerchantChange} // assign onChange function
              /> */}
                <select
                  value={selectedMerchant}
                  className="form-control selectMerchant"
                  id="selectMerchantId"
                  name="selectMerchant"
                  data-e2e="selectMerchant"
                  onChange={(e) => {
                    onMerchantChange(e.target.value);
                  }}
                >
                  <option value={"{}"}>Select Merchant</option>
                  {newMerchantList && newMerchantList.length > 0 && newMerchantList.map((data, index) => {
                    return (
                      <option key={index} data-e2e={data.name} value={JSON.stringify(data)}>{data.name}</option>
                    )
                  })}
                </select>
                {/* <Select
                placeholder="Select Merchant"
                getOptionLabel={(merchantList) => merchantList.name}
                getOptionValue={merchantList.find(
                  (obj) => obj.value === selectedMerchant
                )} // set selected value
                options={merchantList} // set list of the data
                onChange={onMerchantChange} // assign onChange function
              /> */}

                {Object.keys(selectedMerchant).length > 0 && (
                  <div style={{ marginTop: 20, lineHeight: "25px" }}>

                    <div className="form-group row">
                      <label htmlFor="network" className="col-3 col-form-label">Network:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="network"
                          data-e2e="network"
                          id="network"
                          value={inputState.network}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="merchantId" className="col-3 col-form-label">Id:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="merchantId"
                          id="merchantId"
                          data-e2e="merchantId"
                          value={inputState.merchantId}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="merchantName" className="col-3 col-form-label">Name:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="merchantName"
                          id="merchantName"
                          data-e2e="merchantName"
                          value={inputState.merchantName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="merchantUserEmail" className="col-3 col-form-label">User Email :</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="merchantUserEmail"
                          id="merchantUserEmail"
                          data-e2e="merchantUserEmail"
                          value={inputState.merchantUserEmail}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="merchantUserId" className="col-3 col-form-label">UserId:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="merchantUserId"
                          id="merchantUserId"
                          data-e2e="merchantUserId"
                          value={inputState.merchantUserId}
                          onChange={handleChange}
                        />
                      </div>
                    </div>


                    <div className="form-group row">
                      <label htmlFor="apiKey" className="col-3 col-form-label">ApiKey:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="apiKey"
                          id="apiKey"
                          data-e2e="apiKey"
                          value={inputState.apiKey}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="firstName" className="col-3 col-form-label">firstName:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="firstName"
                          id="firstName"
                          value={inputState.firstName}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 

                    <div className="form-group row">
                      <label htmlFor="lastName" className="col-3 col-form-label">lastName:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="lastName"
                          id="lastName"
                          value={inputState.lastName}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 
                    
                    <div className="form-group row">
                      <label htmlFor="billingAddress1" className="col-3 col-form-label">billingAddress1:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="billingAddress1"
                          id="billingAddress1"
                          value={inputState.billingAddress1}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 
                    
                     <div className="form-group row">
                      <label htmlFor="billingCity" className="col-3 col-form-label">billingCity:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="billingCity"
                          id="billingCity"
                          value={inputState.billingCity}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 

                     <div className="form-group row">
                      <label htmlFor="billingPostcode" className="col-3 col-form-label">billingPostcode:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="billingPostcode"
                          id="billingPostcode"
                          value={inputState.billingPostcode}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 

                    <div className="form-group row">
                      <label htmlFor="billingState" className="col-3 col-form-label">billingState:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="billingState"
                          id="billingState"
                          value={inputState.billingState}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 

                     <div className="form-group row">
                      <label htmlFor="billingCountry" className="col-3 col-form-label">billingCountry:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="billingCountry"
                          id="billingCountry"
                          value={inputState.billingCountry}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 
                     <div className="form-group row">
                      <label htmlFor="returnToMerchantUrl" className="col-3 col-form-label">returnToMerchantUrl:</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          name="returnToMerchantUrl"
                          id="returnToMerchantUrl"
                          value={inputState.returnToMerchantUrl}
                          onChange={handleChange}
                        />
                      </div>
                    </div> 

                    {/* <div className="form-group">
                    <b>Merchant Id: </b>{" "}
                    <input
                      className="form-control"
                      name="merchantId"
                      value={inputState.merchantId}
                      onChange={handleChange}
                    />
                  </div> */}
                    {/* <div>
                    <b>Merchant Name: </b>{" "}
                    <input
                      className="form-control"
                      name="merchantName"
                      value={inputState.merchantName}
                      onChange={handleChange}
                    />
                  </div> */}
                    {/* <div>
                    <b>Merchant Email: </b>{" "}
                    <input
                      className="form-control"
                      name="email"
                      value={inputState.email}
                      onChange={handleChange}
                    />
                  </div> */}
                    {/* <div>
                    <b>Merchant UserId: </b>{" "}
                    <input
                      className="form-control"
                      name="merchantUserId"
                      value={inputState.merchantUserId}
                      onChange={handleChange}
                    />
                  </div> */}
                  </div>
                )}
              </div>
            </div>

            <div className="card">
              <h5 className="card-header">Deposit Details</h5>
              <div className="card-body">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{prefix}</span>
                  </div>
                  <input
                    type="number"
                    name="depositAmount"
                    className="form-control"
                    data-e2e="depositAmount"
                    defaultValue={parseFloat(depositValue)}
                    value={parseFloat(depositValue)}
                    onChange={onAmountInputChange}
                    aria-label="Text input with dropdown button"
                  />
                  <Select
                    className="currencyBox"
                    placeholder="Select"
                    data-e2e="currencyBox"
                    getOptionLabel={(currencyList) => currencyList.value}
                    value={currencyList.find(
                      (obj) => obj.value === selectedCurrency
                    )} // set selected value
                    options={currencyList} // set list of the data
                    onChange={onCurrencyChange} // assign onChange function
                  />
                </div>
                <div className="row mt-2 ml-0 mr-0">
                  <div className="col-3 box" data-e2e="plus25" onClick={() => onAmountClick(25)}>
                    +25
                  </div>
                  <div className="col-3 box" data-e2e="plus50" onClick={() => onAmountClick(50)}>
                    +50
                  </div>
                  <div className="col-3 box" data-e2e="plus75" onClick={() => onAmountClick(75)}>
                    +75
                  </div>
                  <div className="col-3 box" data-e2e="plus100" onClick={() => onAmountClick(100)}>
                    +100
                  </div>
                </div>
                {/* <div>
                <b>Api Key: </b>{" "}
                <input
                  className="form-control"
                  name="apiKey"
                  value={inputState.apiKey}
                  onChange={handleChange}
                />
              </div> */}
              </div>
            </div>

            {/* 
        <Card style={{ width: '18rem' }}>
  <Card.Body>
  */}

            <Button
              className="depositBtn"
              size="lg"
              block
              onClick={submitData}
              disabled={!selectedMerchant.id > 0 && !depositValue > 0}
              data-e2e="depositBtn"
            >
              Deposit
            </Button>
            {/* <input type="submit" />
      <select {...register("gender")}>
        <option value="female">female</option>
        <option value="male">male</option>
        <option value="other">other</option>
      </select> */}
            {errorMessage && <div className="error"> {errorMessage} </div>}
            {errorMessage == null && (
              <div>
                {/* <div style={{ wordWrap: "break-word" }}>
                <b>Redirect URL:</b> {redirectUrl}{" "}
              </div> */}
                <div className="form-group row">
                  <label htmlFor="apiKey" className="col-3 col-form-label" data-e2e="redirect-url" data-e2e-url={redirectUrl}>Redirect URL:</label>
                  <div className="col-9" style={{ wordWrap: "break-word" }}>
                    <span> {redirectUrl}</span>
                  </div>
                </div>
                {dimensionsInput.show && <div className="mt-2" style={boxStyle}>
                  <div className="form-group row">
                    <label htmlFor="windowWidth" className="col-3 col-form-label">Width:</label>
                    <div className="col-3">
                      <input
                        className="form-control"
                        name="windowWidth"
                        id="windowWidth"
                        value={dimensionsInput.windowWidth}
                        onChange={handleInputChange}
                      />
                    </div>
                    <label htmlFor="windowHeight" className="col-3 col-form-label">Height:</label>
                    <div className="col-3">
                      <input
                        className="form-control"
                        name="windowHeight"
                        id="windowHeight"
                        value={dimensionsInput.windowHeight}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-6">
                <div class="form-group">
    <label for="windowWidth">Width:</label>
    <input
                      className="form-control"
                      name="windowWidth"
                      value={dimensionsInput.windowWidth}
                      onChange={handleInputChange}
                    />
</div>
               
                </div> */}
                  {/* <div className="col-6">
                  <div class="form-group">
    <label for="windowWidth">Height:</label>
    <input
                      className="form-control"
                      name="windowHeight"
                      value={dimensionsInput.windowHeight}
                      onChange={handleInputChange}
                    />
</div>
                 
                  </div> */}
                  <Button
                    className="depositBtn"
                    size="lg"
                    block
                    onClick={() => {
                      if (dimensionsInput.windowWidth > 0 && dimensionsInput.windowHeight > 0) {
                        window.open(
                          "https://cp-web-dev.cryptonyte.org/cryptoToDeposit?transactionid=0xcb67bc3994f168b30861a465c1aeff2ea67f2bf5a97a802824c84fd85be82400&merchantid=1",
                          "myWindow",
                          `toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=${dimensionsInput.windowWidth},height=${dimensionsInput.windowHeight}`
                        )
                      }
                      else {
                        alert("Values should be greater than 0")
                      }
                    }
                    }
                  >
                    Open
                  </Button>
                  {/* <div className="row">
                  <Button
                  className="depositBtn"
                  onClick={() =>
                    window.open(
                      "https://cp-web-dev.cryptonyte.org/cryptoToDeposit?transactionid=0xcb67bc3994f168b30861a465c1aeff2ea67f2bf5a97a802824c84fd85be82400&merchantid=1",
                      "myWindow",
                      `toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=${dimensionsInput.windowWidth},height=${dimensionsInput.windowHeight}`
                    )
                  }
                >Open
                </Button>
                 </div> */}
                </div>

                }
                <div className="form-group row mt-2">
                  <div className="col-3">
                    <Button
                      className="depositBtn"
                      data-e2e="modal"
                      onClick={() => {
                        setDimensionInput({ show: false })
                        onHide();
                        setRedirectedModal(true);
                        // setRedirectedModalUrl()
                        // window.open(
                        //   "https://cp-web-dev.cryptonyte.org/cryptoToDeposit?transactionid=0xcb67bc3994f168b30861a465c1aeff2ea67f2bf5a97a802824c84fd85be82400&merchantid=1",
                        //   "myWindow",
                        //   "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=939,height=910"
                        // )
                      }
                      }
                    >
                      Modal
                    </Button>
                  </div>
                  <div className="col-3">
                    <Button
                      className="depositBtn"
                      data-e2e="dynamic"
                      onClick={() => setDimensionInput({ show: true })}
                    >
                      Dynamic
                    </Button>
                  </div>
                  <div className="col-3">
                    <Button
                      className="depositBtn"
                      data-e2e="newTab"
                      onClick={() => {
                        setDimensionInput({ show: false });
                          window.open(
                            redirectUrl,
                            "myWindow",
                            "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=1280,height=1080"
                          )
                      }
                      }
                    >
                      New Tab
                    </Button>
                  </div>
                  <div className="col-3">
                    <Button
                      className="depositBtn"
                      data-e2e="iFrame"
                      onClick={() => {
                        setDimensionInput({ show: false });
                          window.open(
                            redirectUrl,
                            "myWindow",
                            "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=939,height=800"
                          )
                          }
                      }
                    >
                      IFrame
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* </form> */}
        </Modal.Body>
      </Modal>
      <Modal show={redirectedModal}>
        <Modal.Body style={{ padding: "0" }} >
          <iframe title="iframe1" data-e2e="iframe1"  src={redirectUrl} width="200" height="200" style={iframeStyle} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
