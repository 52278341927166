import axios from "axios";

const getAllMerchant = () => {
  return axios.get("https://jsonplaceholder.typicode.com/users");

};

const postData = (data,apiUrl) => {
    return axios.post(apiUrl + "/pga/CreatePayment", data,{ headers: {'Content-Type': 'application/json',"api-key":data.apiKey} });
  };

  const postCardData = (data, paymentRedirectUrl) => {
    return axios.post(paymentRedirectUrl + "/pga/CreateSecurePayment", data,{ headers: {'Content-Type': 'application/json',"api-key":data.apiKey} });
  };

const postWalletData = (data,apiUrl) => {
  return axios.post(apiUrl + "/pga/CreatePayout", data, { headers: {'Content-Type': 'application/json',"api-key":data.apiKey} })
}  

export {getAllMerchant,postData,postCardData, postWalletData};