import React from "react";
import RoutesList from "./Routes";
import "./scss/bootstrap.min.css"
import "./scss/style.scss";
import "./scss/style.css";

function App() {
  return (
    <div id="ProjectApp" className="projectApp">
      <RoutesList />
    </div>
  );
}

export default App;
