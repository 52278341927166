const merchantList = [
  {
    value: "1",
    name: "GG Poker",
    email: "merchant@ggpoker.com",
    merchantUserId:'1',
    merchantTransactionid:'exampledatamti'
  },
  {
    value: "UID778H2",
    name: "Party Poker",
    email: "merchant@partypoker.com",
    merchantUserId:'2',
    merchantTransactionid:'exampledatamti'
  },
  {
    value: "UID778H3",
    name: "Amazon",
    email: "merchant@amazon.com",
    merchantUserId:'3',
    merchantTransactionid:'exampledatamti'
  },
  {
    value: "UID778H4",
    name: "Google",
    email: "merchant@google.com",
    merchantUserId:'4',
    merchantTransactionid:'exampledatamti'
  },
  {
    value: "UID778H5",
    name: "Tesla",
    email: "merchant@tesla.com",
    merchantUserId:'5',
    merchantTransactionid:'exampledatamti'
  },
];

const currencyList = [
  { value: "JPY", label: "Japanese yen",symbol:"¥" },
  { value: "GBP", label: "Pound sterling" ,symbol:"£"},

  { value: "AUD", label: "Australian dollar",symbol:"$" },

  { value: "CAD", label: "Canadian dollar",symbol:"$" },

  { value: "EUR", label: "EURO" ,symbol:"€"},

  { value: "HKD", label: "Hong Kong dollar",symbol:"$" },

  { value: "JMD", label: "Jamaican dollar",symbol:"$" },

  { value: "NZD", label: "New Zealand dollar" ,symbol:"$"},

  { value: "SGD", label: "Singapore dollar",symbol:"$" },

  { value: "USD", label: "United States dollar",symbol:"$" },
];

const cryptoCurrencyList = [
  { value: "BTC", label: "Bitcoin"},
  { value: "ETH", label: "Ethereum"},
  { value: "TRX", label: "Tron" },
  { value: "TRC20-USDT", label: "TRC20-USDT" },

  { value: "BCC", label: "Bitcoin Cash"},

  { value: "USDC", label: "USD Coin" },
];

const newMerchantList = [
  { 
    "_id" : "612a8252fd75eeaf6e8c4adb", 
    "merchantId" : "1", 
    "name" : "PartyPoker", 
    "companyId" : "", 
    "merchantUserId" : "1mUsrId", 
    "apiKey" : "CorrectSecretKey", 
    "testApiKey" : {
        "key" : "4c70dbb3-4260-4f58-829f-61003b669c48", 
        "createdDate" : "2021-09-06T03:51:25.897+0000"
    }, 
    "prodApiKey" : {
        "key" : "728767fb-63cd-4564-81bc-3a8d36026113", 
        "createdDate" : "2021-09-06T03:51:25.898+0000"
    }, 
    "activitation" : true, 
    "activitationDate" : "2021-08-28T18:37:06.720+0000", 
    "contacts" : [
        "611fa8259db846cf15b0eff9"
    ], 
    "redirectUrl" : "PPRedirectURL", 
    "callbackUrls" : {
        "confirmation" : "https://callBackURL.com", 
        "billingAgreement" : "cbkbillingagreement"
    }, 
    "lastMerchantTransactionId" : "Last Merchant Transaction Id", 
    "lastSettlementTransferId" : "Last Settlement Transfer Id", 
    "cryptos" : [
        "612a8252fd75eeaf6e8c4adf", 
        "612a8252fd75eeaf6e8c4ae0"
    ], 
    "supportEmail" : "support@test2.com", 
    "__v" : 25, 
    "revealedDate" : "2021-09-06T13:26:19.166+05:30"
},
{ 
    "_id" : "6131c0d24525d2814efd5466", 
    "cryptos" : [
        "612a8252fd75eeaf6e8c4adf", 
        "612a8252fd75eeaf6e8c4ae0"
    ], 
    "contacts" : [
        "6131c0d24525d2814efd5464"
    ], 
    "merchantId" : "merchant760923", 
    "name" : "Mac Poker", 
    "lockingPeriod" : "Invalid date", 
    "testApiKey" : {
        "key" : "b5e3029e-b758-4475-a064-3f111a23f8c7", 
        "createdDate" : "2021-09-06T03:51:25.897+0000"
    }, 
    "prodApiKey" : {
        "key" : "b9f7947b-8f58-46a3-adbc-581b327da28d", 
        "createdDate" : "2021-09-06T03:51:25.898+0000"
    }, 
    "callbackUrls" : {
        "confirmation" : "cbkconfirmation", 
        "billingAgreement" : "cbkbillingagreement"
    }, 
    "activitation" : true, 
    "activitationDate" : "2021-08-28T18:37:06.720+0000", 
    "supportEmail" : "support@test.com", 
    "__v" : 0
}
]

const withdrawMerchantList = [
  { 
    "_id" : "612a8252fd75eeaf6e8c4adb", 
    "merchantId" : "1", 
    "name" : "testMerchant", 
    "companyId" : "", 
    "merchantUserId" : "1mUsrId", 
    "apiKey" : "CorrectSecretKey", 
    "testApiKey" : {
        "key" : "4c70dbb3-4260-4f58-829f-61003b669c48", 
        "createdDate" : "2021-09-06T03:51:25.897+0000"
    }, 
    "prodApiKey" : {
        "key" : "728767fb-63cd-4564-81bc-3a8d36026113", 
        "createdDate" : "2021-09-06T03:51:25.898+0000"
    }, 
    "activitation" : true,
    "network": "testnet",
    "activitationDate" : "2021-08-28T18:37:06.720+0000", 
    "contacts" : [
        "611fa8259db846cf15b0eff9"
    ], 
    "redirectUrl" : "PPRedirectURL", 
    "callbackUrls" : {
        "confirmation" : "https://callBackURL.com", 
        "billingAgreement" : "cbkbillingagreement"
    }, 
    "lastMerchantTransactionId" : "Last Merchant Transaction Id", 
    "lastSettlementTransferId" : "Last Settlement Transfer Id", 
    "cryptos" : [
        "612a8252fd75eeaf6e8c4adf", 
        "612a8252fd75eeaf6e8c4ae0"
    ], 
    "supportEmail" : "support@test2.com", 
    "__v" : 25, 
    "revealedDate" : "2021-09-06T13:26:19.166+05:30"
},
{ 
    "_id" : "6131c0d24525d2814efd5466", 
    "cryptos" : [
        "612a8252fd75eeaf6e8c4adf", 
        "612a8252fd75eeaf6e8c4ae0"
    ], 
    "contacts" : [
        "6131c0d24525d2814efd5464"
    ], 
    "merchantId" : "merchant424977", 
    "merchantUserId" : "1mUsrId", 
    "name" : "Primal Poker", 
    "lockingPeriod" : "15", 
    "testApiKey" : {
      "key" : "c6966a79-84d9-466b-a5af-9c795c7ce6cb",
      "createdDate" :"2021-10-07T13:59:06.912Z"
  },
  "prodApiKey" : {
      "key" : "1631505c-dccf-4ba2-9d6b-6bf03dac2ea2",
      "createdDate" : "2021-10-07T13:59:06.913Z"
  },
    "callbackUrls" : {
        "confirmation" : "cbkconfirmation", 
        "billingAgreement" : "cbkbillingagreement"
    }, 
    "activitation" : true, 
    "network": "testnet",
    "activitationDate" : "2021-08-28T18:37:06.720+0000", 
    "supportEmail" : "paul.mccarthy@badbeat.com", 
    "__v" : 0
}
]

export { merchantList, currencyList, newMerchantList, cryptoCurrencyList, withdrawMerchantList };

