import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DepositModal } from "../../components/v2/DepositModal_v2";
import { GatewayModal } from "../../components/v2/GatewayModal_v2";
import * as DepositService from "../../services/DepositService";
import moment from "moment";

const Home_v2 = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const apiUrl = useSelector((state) => state.config.api_url)
  const [showDepositModal, setDepositModal] = useState(false);
  const [depositValue, setDepositValue] = useState(0);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showGatewayModal, setGatewayModal] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [prefix, setPrefix] = useState("$")
  const [errorMessage, setErrorMessage] = React.useState("");
  const [inputState, setInputState] = React.useState({
    merchantId: "",
    merchantName: "",
    merchantUserEmail: "",
    merchantUserId: '',
    merchantTransactionid: '',
    apiKey: 'CorrectSecretKey',
    network: 'test',
  })
  const [redirectedModal, setRedirectedModal] = useState(false);

  function handleChange(evt) {
    const value = evt.target.value;
    setInputState({
      ...inputState,
      [evt.target.name]: value
    });
  }

  function onAmountInputChange(evt) {
    const value = evt.target.value;
    setDepositValue(parseFloat(value));
  }

  const onAmountClick = (value) => {
    setDepositValue(depositValue + value)
  }
  const submitData = () => {
    const dataObj = {
      merchantId: 'merchant424977',
      name: 'Primal Poker',
      merchantUserEmail: 'paul.mccarthy@badbeat.com',
      amount: (depositValue * 100).toString(),
      currency: selectedCurrency,
      brand: 'exampledatabrand',
      useBrand: 'exampledatausebrand',
      merchantUserId: 'username',
      apiKey: 'c6966a79-84d9-466b-a5af-9c795c7ce6cb',
      network: 'mainnet',
      merchantTransactionId: "merchanttransaction-" + moment().valueOf()
    };
    DepositService.postData(dataObj, apiUrl)
      .then((response) => {
        if (response.data.Error) {
          setErrorMessage(response.data.Error)
          return
        }
        else {
          setErrorMessage(null);
          setDepositModal(false)

          setRedirectUrl(response.data.transactionURL)
          setRedirectedModal(true);
        }
      })
      .catch((e) => {
        if (e) {
          setErrorMessage("Server Error")
          return
        }

      });
  };

  return (
    <div>
      <img src={`${image_base_url}/merchantDeposit/fakemerchantnew.jpg`} alt="Workplace" useMap="#workmap" />

      <map name="workmap">
        <area target="" alt="" title="" href="#" coords="6,-1,2876,202" shape="rect" onClick={() => setGatewayModal(true)} />
      </map>
      <GatewayModal show={showGatewayModal} onHide={() => setGatewayModal(false)} onClick={() => {
        setGatewayModal(false)
        setDepositModal(true);
        ;
      }} />

      <DepositModal show={showDepositModal} onHide={() => setDepositModal(false)} onMerchantChange={(event) => {
        setInputState({
          merchantId: event.merchantId,
          merchantName: event.name,
          merchantUserId: event.merchantUserId,
          merchantTransactionid: event.merchantTransactionid,
          merchantUserEmail: event.supportEmail,
          apiKey: event.testApiKey.key,
          network: event.network ? event.network : inputState.network
        })
        setSelectedMerchant(event);
      }}
        onAmountInputChange={onAmountInputChange}
        onCurrencyChange={(event) => {
          setPrefix(event.symbol)
          setSelectedCurrency(event.value);
        }}
        handleChange={handleChange}
        inputState={inputState}
        onAmountClick={onAmountClick}
        depositValue={depositValue} selectedMerchant={selectedMerchant}
        selectedCurrency={selectedCurrency} prefix={prefix} submitData={submitData}
        errorMessage={errorMessage} redirectUrl={redirectUrl}
        redirectedModal={redirectedModal}
        onClose={() => setRedirectedModal(false)} />
    </div>
  );
};


export default Home_v2