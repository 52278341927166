import React from "react";
import Home from "./pages/Home";
import HOME_V2 from "./pages/v2/Home_v2";
import HOME_LUCKYBLOCK from "./pages/v2/Home_LuckyBlock.js";
import HOME_V2_WITHDRAW from "./pages/v2/Home_v2_Withdraw";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const RoutesList = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/TestMerchant" element={<HOME_V2 />} />
        <Route exact path="/LuckyBlock" element={<HOME_LUCKYBLOCK />} />
        <Route exact path="/TestMerchantDemo" element={<HOME_V2_WITHDRAW />} />
      </Routes>
    </Router>
  );
};

export default RoutesList;
