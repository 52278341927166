import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DepositModal } from "../../components/v2/DepositModal_v2";
import * as DepositService from "../../services/DepositService";
import moment from "moment";

const Home_LuckyBlock = () => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const apiUrl = useSelector((state) => state.config.api_url)
    const [showDepositModal, setDepositModal] = useState(false);
    const [depositValue, setDepositValue] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("USD");
    const [prefix, setPrefix] = useState("$")
    const [errorMessage, setErrorMessage] = React.useState("");
    const [redirectedModal, setRedirectedModal] = useState(false);
    const [inputState, setInputState] = useState("")

    function handleChange(evt) {
        const value = evt.target.value;
        setInputState({
            ...inputState,
            [evt.target.name]: value
        });
    }

    function onAmountInputChange(evt) {
        const value = evt.target.value;
        setDepositValue(parseFloat(value));
    }

    const onAmountClick = (value) => {
        setDepositValue(depositValue + value)
    }
    const submitData = () => {
        const dataObj = {
            merchantId: "1",
            name: "testMerchant",
            merchantUserEmail: "support@cryptonpay.com",
            amount: (depositValue * 100).toString(),
            currency: selectedCurrency,
            merchantUserId: 'luckyblock',
            apiKey: '4c70dbb3-4260-4f58-829f-61003b669c48',
            network: 'testnet',
            merchantTransactionId: "merchanttransaction-" + moment().valueOf(),
        };
        DepositService.postData(dataObj, apiUrl)
            .then((response) => {
                if (response.data.Error) {
                    setErrorMessage(response.data.Error)
                    return
                }
                else {
                    setErrorMessage(null);
                    setDepositModal(false)

                    // setShow2(true);
                    setRedirectUrl(response.data.transactionURL)
                    setRedirectedModal(true);
                }
            })
            .catch((e) => {
                if (e) {
                    setErrorMessage("Server Error")
                    return
                }

            });
    };

    return (
        <div>
            <img src={`${image_base_url}/merchantDeposit/fakemerchantluckyblock.png`} alt="Workplace" useMap="#workmap" />

            <map name="workmap">
                <area target="" alt="" title="" href="#" coords="6,-1,300,300" shape="rect" onClick={() => setDepositModal(true)} />
            </map>
            <DepositModal show={showDepositModal} hideMerchantDetails={true} onHide={() => setDepositModal(false)} 
                onAmountInputChange={onAmountInputChange}
                onCurrencyChange={(event) => {
                    setPrefix(event.symbol)
                    setSelectedCurrency(event.value);
                }}
                handleChange={handleChange}
                onAmountClick={onAmountClick}
                depositValue={depositValue}
                selectedCurrency={selectedCurrency} prefix={prefix} submitData={submitData}
                errorMessage={errorMessage} redirectUrl={redirectUrl}
                redirectedModal={redirectedModal}
                onClose={() => setRedirectedModal(false)} />
            {/*----------------Modal 3 starts ---------------*/}

        </div>
    );
};


export default Home_LuckyBlock