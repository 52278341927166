import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from "styled-components";

const ModalStyles = styled.div`
  background-image: url("${props => props.imageUrl}");
  width: 100%;
  height: 100vh;
  padding: 0px;
  background-size: cover;
  position: absolute;
  margin-top: -1px;
`;

export const GatewayModal = ({show,onHide,onClick}) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
    return (
        <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1d1d1d" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#fff" }}
          >
            <span data-e2e="makeADeposit">Make a Deposit</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding:"0px",backgroundColor:"#000"}} onClick={onClick}
        data-e2e="modalContentClick"
        >
          <ModalStyles  imageUrl={`${image_base_url}/merchantDeposit/modalbg.png`} />
        </Modal.Body>
      </Modal>
    )
}
