import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GatewayModal } from "../../components/v2/WithDrawGatewayModal_v2";
import { WithDrawModal } from "../../components/v2/WithDrawModal_v2";
import * as DepositService from "../../services/DepositService";

const Home_v2_Withdraw = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const apiUrl = useSelector((state) => state.config.api_url);
  const [showWithDrawModal, setWithDrawModal] = useState(false);
  const [withdrawValue, setDepositValue] = useState(0);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showGatewayModal, setGatewayModal] = useState(false);
  const [selectedCyptoCurrency, setSelectedCyptoCurrency] = useState({});
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [prefix, setPrefix] = useState("$");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [color, setColor] = useState("white");
  const [inputState, setInputState] = React.useState({
    recipientWallet: "",
    merchantId: "",
    merchantName: "",
    merchantUserEmail: "",
    merchantUserId: "",
    merchantTransactionid: "",
    apiKey: "CorrectSecretKey",
    network: "testnet",
  });
  const [redirectedModal, setRedirectedModal] = useState(false);

  function handleChange(evt) {
    const value = evt.target.value;
    setInputState({
      ...inputState,
      [evt.target.name]: value,
    });
  }

  function onAmountInputChange(evt) {
    const value = evt.target.value;
    setDepositValue(parseFloat(value));
  }

  const onAmountClick = (value) => {
    setDepositValue(withdrawValue + value);
  };
  const submitData = () => {
    if(inputState.recipientWallet){
      const dataObj = {
        currency: selectedCurrency,
        recipientWallet: inputState.recipientWallet,
        cryptoCurrency: selectedCyptoCurrency ? selectedCyptoCurrency.value : "",
        comment: "comment.......",
        merchantId: inputState.merchantId,
        name: inputState.merchantName,
        merchantUserEmail: inputState.merchantUserEmail,
        amount: (withdrawValue * 100).toString(),
        brand: "exampledatabrand",
        useBrand: "exampledatausebrand",
        merchantUserId: inputState.merchantUserId,
        merchantTransactionId: inputState.merchantTransactionid,
        apiKey: inputState.apiKey,
        network: inputState.network,
        hostname: window.location.hostname,
      };
      DepositService.postWalletData(dataObj, apiUrl)
        .then((response) => {
          if (response.data.Error) {
            setRedirectUrl(null);
            setErrorMessage(response.data.Error);
            return;
          } else {
            setErrorMessage(null);
            // setWithDrawModal(false)
  
            setRedirectUrl("Your withdrawal request is now being processed");
            // setRedirectUrl(response.data.transactionURL)
            // setRedirectedModal(true);
          }
        })
        .catch((e) => {
          if (e) {
            setRedirectUrl(null);
            setErrorMessage("Server Error");
            return;
          }
        });
    }else{
      setColor("red");
      document.getElementById("recipientWallet").focus();
    }
   
  };

  return (
    <div>
      <img
        src={`${image_base_url}/merchantDeposit/fakemerchantwithdraw.jpg`}
        alt="Workplace"
        useMap="#workmap"
      />

      <map name="workmap">
        <area
          target=""
          alt=""
          title=""
          href="#"
          coords="6,-1,2876,202"
          shape="rect"
          onClick={() => {
            setInputState({ recipientWallet: "" });
            setGatewayModal(true);
          }}
        />
      </map>
      <GatewayModal
        show={showGatewayModal}
        onHide={() => setGatewayModal(false)}
        onClick={() => {
          setGatewayModal(false);
          setWithDrawModal(true);
        }}
      />

      <WithDrawModal
        show={showWithDrawModal}
        onHide={() => setWithDrawModal(false)}
        onCyptoCurrencyChange={(event) => {
          setSelectedCyptoCurrency(event);
        }}
        onMerchantChange={(event) => {
          let eventObj = JSON.parse(event);
          if (event === "{}") {
            setInputState({
              merchantId: "",
              merchantName: "",
              merchantUserEmail: "",
              merchantUserId: "",
              merchantTransactionid: "",
              apiKey: "CorrectSecretKey",
              network: "testnet",
            });
            setSelectedMerchant({});
          } else {
            setInputState({
              merchantId: eventObj.merchantId,
              merchantName: eventObj.name,
              merchantUserId: eventObj.merchantUserId,
              merchantTransactionid: eventObj.merchantTransactionid,
              merchantUserEmail: eventObj.supportEmail,
              apiKey: eventObj.testApiKey.key,
              network: eventObj.network ? eventObj.network : inputState.network,
            });
            setSelectedMerchant(event);
          }
        }}
        onAmountInputChange={onAmountInputChange}
        onCurrencyChange={(event) => {
          setPrefix(event.symbol);
          setSelectedCurrency(event.value);
        }}
        handleChange={handleChange}
        inputState={inputState}
        onAmountClick={onAmountClick}
        withdrawValue={withdrawValue}
        selectedCyptoCurrency={selectedCyptoCurrency}
        selectedCurrency={selectedCurrency}
        prefix={prefix}
        submitData={submitData}
        selectedMerchant={selectedMerchant}
        errorMessage={errorMessage}
        redirectUrl={redirectUrl}
        redirectedModal={redirectedModal}
        onClose={() => setRedirectedModal(false)}
        color={color}


      />

      {/*----------------Modal 3 starts ---------------*/}
    </div>
  );
};

export default Home_v2_Withdraw;
