import React from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import {
  currencyList
} from "../../data/merchantList";


const iframeStyle = {
  height: "640px",
  width: "100%",
}

export const DepositModal = ({
  show,
  onHide,
  errorMessage,
  depositValue,
  hideMerchantDetails,
  selectedMerchant,
  onAmountInputChange,
  selectedCurrency,
  onCurrencyChange,
  prefix,
  submitData,
  onAmountClick,
  redirectUrl,
  redirectedModal,
  onClose
}) => {

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1d1d1d" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#fff" }}
          >
            Make a Deposit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px", backgroundColor: "#000" }}>
          {!hideMerchantDetails && <div className="backDiv">
          <i className="fa-solid fa-arrow-left"></i>
            <br />
            <span style={{ fontWeight: "900", fontSize: "1.2rem" }}>Deposit</span>
            <p>Please select payment method</p>
          </div>}

          {!hideMerchantDetails && <div className="warningDiv">
            <span>
              KYC verfication is required within 27 days of your registration.
              Please proceed with the verification today to continue using our
              service.
            </span>
            <span>
              <u>Click Here</u>
            </span>{" "}
          </div>}
          <div className="mainDiv">
            {!hideMerchantDetails && <div>
              <span>CRYPTON</span>
              <br />
              <span>PAYMENTS</span>
            </div>}

            <div className="card">
              <h5 className="card-header">Deposit Details</h5>
              <div className="card-body">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{prefix}</span>
                  </div>
                  <input
                    type="number"
                    name="depositAmount"
                    className="form-control"
                    defaultValue={parseFloat(depositValue)}
                    value={parseFloat(depositValue)}
                    onChange={onAmountInputChange}
                    aria-label="Text input with dropdown button"
                  />
                  <Select
                    className="currencyBox"
                    placeholder="Select"
                    getOptionLabel={(currencyList) => currencyList.value}
                    value={currencyList.find(
                      (obj) => obj.value === selectedCurrency
                    )} // set selected value
                    options={currencyList} // set list of the data
                    onChange={onCurrencyChange} // assign onChange function
                  />
                </div>
                <div className="row mt-2 ml-0 mr-0">
                  <div className="col-3 box" onClick={() => onAmountClick(25)}>
                    +25
                  </div>
                  <div className="col-3 box" onClick={() => onAmountClick(50)}>
                    +50
                  </div>
                  <div className="col-3 box" onClick={() => onAmountClick(75)}>
                    +75
                  </div>
                  <div className="col-3 box" onClick={() => onAmountClick(100)}>
                    +100
                  </div>
                </div>
              </div>
            </div>


            <Button
              className="depositBtn"
              size="lg"
              block
              onClick={submitData}
              disabled={!selectedMerchant.id > 0 && !depositValue > 0}
            >
              Deposit
            </Button>

            {errorMessage && <div className="error"> {errorMessage} </div>}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={redirectedModal} onHide={onClose} dialogClassName="my-modal">
        <Modal.Header closeButton style={{ backgroundColor: "#1d1d1d" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#fff" }}
          >
            Send Deposit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0", backgroundColor: "#000" }} >
          <iframe title="iframe3" data-e2e="iframe3" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" src={redirectUrl} width="500" height="500" style={iframeStyle} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
